<div
    class="bazis-container bh-hide-print"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            text: textSettings$ | async,
            role: role$ | async,
            agencyContract: agencyContract$ | async,
            organization: organization$ | async
        } as data"
    >
        <ng-container
            *ngIf="
                notificationsToShow['noAdmin'] &&
                data.text &&
                data.text['noAdmin'] &&
                data.role !== ROLE.base &&
                data.organization &&
                !data.organization.$snapshot.has_users_admin
            "
            [ngTemplateOutlet]="notificationTpl"
            [ngTemplateOutletContext]="{
                id: 'noAdmin',
                text: data.text['noAdmin'],
                textParams: { organizationName: data.organization.$snapshot.name_short },
                routerLink: '/user-initial'
            }"
        ></ng-container>
        <ng-container
            *ngIf="
                notificationsToShow['agencyContract'] &&
                data.text &&
                data.text['agencyContract'] &&
                data.role === ROLE.transporter &&
                ((!data.agencyContract && data.agencyContract !== undefined) ||
                    (data.agencyContract && data.agencyContract.$snapshot.status.id !== 'active'))
            "
            [ngTemplateOutlet]="notificationTpl"
            [ngTemplateOutletContext]="{
                id: 'agencyContract',
                text: data.text['agencyContract']
            }"
        ></ng-container>
        <ng-container
            *ngIf="
                notificationsToShow['requisites'] &&
                data.text &&
                data.text['requisites'] &&
                data.role === ROLE.director &&
                data.organization?.$snapshot?.status?.id !== 'accepted'
            "
            [ngTemplateOutlet]="notificationTpl"
            [ngTemplateOutletContext]="{
                id: 'requisites',
                text: data.text['requisites']
            }"
        ></ng-container>
    </ng-container>
</div>

<ng-template
    #notificationTpl
    let-id="id"
    let-text="text"
    let-textParams="textParams"
    let-routerLink="routerLink"
>
    <div
        *transloco="let t"
        class="bazis-block bazis-block_warning bh-margin-bottom-4x"
    >
        <bazis-icon
            color="warning"
            name="exclamation"
            size="md"
        ></bazis-icon>
        <div class="bh-flex bh-justify-content-between">
            <div class="bazis-block__content">
                <p class="bh-no-margin">
                    <span [innerHTML]="t('topNotifications.' + id + '.' + text, textParams)"></span>
                    <bazis-link
                        *ngIf="routerLink"
                        borderStyle="none"
                        size="s"
                        class="bh-margin-start-2x"
                        [routerLink]="routerLink"
                    >
                        <bazis-icon src="/assets/icons/other/open.svg"></bazis-icon>
                    </bazis-link>
                </p>
            </div>

            <bazis-button
                size="small"
                fill="clear"
                (click)="hideNotification(id)"
            >
                <bazis-icon
                    slot="icon-only"
                    name="cross"
                ></bazis-icon>
            </bazis-button>
        </div>
    </div>
</ng-template>
