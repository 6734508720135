import * as L from 'leaflet';

const ExtendedCanvas = L.Canvas.extend({
    _updatePoly: function (layer, closed) {
        if (layer.options.border && layer.options.main) {
            //рисуем подложку (границу)
            layer.options.weight = layer.options.main.weight + 2 * layer.options.border.weight;
            layer.options.color = layer.options.border.color;
            L.Canvas.prototype._updatePoly.call(this, layer, closed);

            // основная линия
            layer.options.weight = layer.options.main.weight;
            layer.options.color = layer.options.main.color;
            L.Canvas.prototype._updatePoly.call(this, layer, closed);

            // верхний слой для охвата всей линии по ширине
            layer.options.weight = layer.options.main.weight + 2 * layer.options.border.weight;
            layer.options.color = 'transparent';
            L.Canvas.prototype._updatePoly.call(this, layer, closed);
        } else {
            L.Canvas.prototype._updatePoly.call(this, layer, closed);
        }
    },

    _updateCanvasText(layer) {
        if (!layer.options.text?.value) return;
        this._ctx.save();
        this._ctx.font = `${layer.options.text.fontWeight} ${layer.options.text.fontSize}${layer.options.text.fontSizeUnits} ${layer.options.text.fontFamily}`;
        this._ctx.fillStyle = layer.options.text.color;
        this._ctx.textAlign = layer.options.text.align;
        this._ctx.textBaseline = layer.options.text.baseLine;
        this._ctx.translate(layer._point.x, layer._point.y);
        this._ctx.fillText(layer.options.text.value, 0, 0);
        this._ctx.restore();
    },
});

export default ExtendedCanvas;
