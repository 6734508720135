import { Component } from '@angular/core';
import * as L from 'leaflet';

export type MapSettings = {
    id?: string;
    controls?: MapControlGroup[];
    defaultLocation: L.LatLng;
    defaultZoom?: L.LatLng;
    tile?: MapTile;
    tiles?: MapTileSettings[];
    additionalSettings?: {
        doubleClickZoom?: boolean;
        closePopupOnClick?: boolean;
        dragging?: boolean;
        zoomSnap?: boolean;
        zoomDelta?: boolean;
        trackResize?: boolean;
        touchZoom?: boolean;
        scrollWheelZoom?: boolean;
        zoom?: number;
        padding?: {
            paddingTopLeft?: [number, number];
            paddingBottomRight?: [number, number];
            padding?: [number, number];
        };
    };
};

export type MapTileSettings = {
    tile: MapTile;
    nameKey?: string;
    isDefault?: boolean;
    id: string;
};

export type MapTile = {
    url: string;
    size: number;
};

export type MapControlGroup = {
    position: string;
    groups: MapControl[][];
};

export type MapControl = {
    type: string;
    typeSettings: any;
};

export type MapLayerSettings = {
    id: string;
    type?: 'marker' | 'line' | 'polygon';
    isEditable?: boolean;
    isInteractive?: boolean;
    hasPopup?: boolean;
    openPopupOnHover?: boolean;
    openPopupOnHoverForCluster?: boolean;
    hasTooltip?: boolean;
    tooltipSettings?: {
        tooltipFunction?: Function;
        tooltipText?: string;
        tooltipContent?: string;
        direction?: string;
        className?: string;
        offset?: number[];
        isRevertActionTooltip?: boolean;
    };
    fitBounds?: boolean;
    forceFit?: boolean; // force event when layer has already existed
    objects?: MapLayerObject[];
    zoomObjects?: { [index: string]: MapLayerObject[] };
    hideClusterCount?: boolean;
    skipZoomInOnClick?: boolean;
    hidePopupAfterZoom?: boolean;
    popupComponent?: Component;
    popupComponentTag?: string;
    resetSelectionWhenClosePopup?: boolean;
    popupComponentParams?: any;
    avoidReselectionOnZoom?: boolean; // do not reselect object after zoom
    zIndex: number;
    updatedTime?: number;
    zoomIconSettings?: { zoomMin: number; zoomMax: number; icon: MapIcon }[];
    icon?: MapIcon;
    zoomIcon?: { [index: string]: MapIcon };
    line?: MapLine;
    polygon?: MapPolygon;
    defaultOptions?: any;
    selectedOptions?: any;
    clusterOptions?: any;
    selectedClusterOptions?: any;
    switchOffToggleClickMode?: boolean;
};

export type MapLayers = {
    [index: string]: MapLayerSettings;
};

export type MapLayerObject = {
    id: string;
    //geojson geometry
    geometry: MapGeometry;
    coordinates?: number[]; // leaflet coordinates
    params?: any;
    icon?: MapIcon;
    line?: MapLine;
    polygon?: MapPolygon;
    rotate?: number;
    count?: number; //cluster flag (contains number of objects in cluster)
    nextZoom?: number;
};

export type MapGeometry = {
    coordinates: any[];
    type: string;
};

export type LatLng = {
    lat: number;
    lng: number;
};

export type MapIcon = {
    type: 'svgTpl' | 'url';
    default: MapIconParams;
    selected?: MapIconParams;
    cluster?: MapIconParams;
    selectedCluster?: MapIconParams;
};

export type MapLine = {
    type: 'line';
    default: MapLineParams;
    selected?: MapLineParams;
};

export type MapPolygon = {
    type: 'polygon';
    default: MapPolygonParams;
    selected?: MapPolygonParams;
};

export type MapIconParams = {
    svgTpl?: string;
    svgParams?: any;
    width?: number;
    height?: number;
    offset?: {
        x: number;
        y: number;
    };
    url?: string;
    text?: string;
    tooltipText?: string;
    rotate?: number;
};

export type MapPolygonParams = {
    stroke?: boolean;
    weight?: number;
    color: string;
    opacity?: number;
    fill?: boolean;
    fillColor?: string;
    fillOpacity?: number;
};

export type MapLineParams = {
    border: {
        weight: number;
        color: string;
    };
    main: {
        weight: number;
        color: string;
    };
};

export type MapDraggedEvent = {
    layerId?: string;
    objectId?: string;
    coordinates: LatLng;
};

export type MapPopupInfo = {
    title: string;
    point: {
        type: string;
        coordinates: number[];
    };
    desctiption?: string;
};

export type DefaultPopupSettings = {
    id: string;
    layerId: string;
    title?: string;
    titleKey?: string;
    description: string;
    buttons: {
        title: string;
        action: string;
        icon?: string;
        type?: string;
    }[];
};
