<ng-container
    *ngIf="{
        types: authTypes$ | async,
        phoneToken: pinSent$ | async,
        authing: authing$ | async,
        timer: timer$ | async
    } as data"
>
    <div
        *transloco="let t"
        class="bazis-block bazis-block_outline sl-entrance-block"
    >
        <div class="bazis-block__header">
            <h4 class="bh-text-sm-center">{{ t('login.title') }}</h4>
        </div>

        <ng-container *ngIf="!showPhoneCodeComponent && data.types">
            <bazis-input-options-button
                [formControl]="userType"
                [options]="userTypeOptions"
                [required$]="required$"
                [multiple]="false"
            ></bazis-input-options-button>

            <bazis-link
                class="bh-hide-sm-up bh-margin-bottom-8x"
                borderStyle="none"
                target="_blank"
                href="/faq/1/7"
                fontWeight="400"
            >
                <bazis-icon
                    slot="start"
                    name="interrogation"
                ></bazis-icon>
                {{ t('login.faq') }}
            </bazis-link>

            <div
                *ngIf="data.types.password && authTypesToTabsMap.password === userType.value"
                class="sl-entrance__type"
            >
                <!-- && (showForm || !types.esia) -->
                <form
                    id="sewage-login-form"
                    [formGroup]="loginForm"
                    (keyup.enter)="loginForm.valid && login(loginForm)"
                    (submit)="loginForm.valid && login(loginForm)"
                >
                    <div class="bazis-control">
                        <label class="bazis-control__label">
                            {{ t('login.loginField') }}
                            <span color="danger">*</span>
                        </label>
                        <div class="bazis-control__field-group">
                            <input
                                #usernameInput
                                class="bazis-control__field"
                                type="text"
                                id="sewage-username"
                                autocomplete="username"
                                formControlName="username"
                                (focus)="notValidAuth$.next(false)"
                            />
                        </div>
                    </div>
                    <div class="bazis-control">
                        <div class="bh-flex bh-justify-content-between">
                            <label class="bazis-control__label">
                                {{ t('login.passwordField') }}
                                <span color="danger">*</span>
                            </label>
                            <bazis-link
                                tabindex="-1"
                                borderStyle="none"
                                class="sl-forgotten-pass"
                                [routerLink]="'/login/forgot-password'"
                            >
                                {{ t('login.forgotPassword.title') }}
                            </bazis-link>
                        </div>
                        <div class="bazis-control__field-group">
                            <div class="bazis-control__field-wrapper">
                                <input
                                    class="bazis-control__field"
                                    [type]="showPass ? 'text' : 'password'"
                                    formControlName="password"
                                    id="current-password"
                                    autocomplete="current-password"
                                    (focus)="notValidAuth$.next(false)"
                                />
                            </div>
                            <div class="bazis-control__field-group__end">
                                <bazis-button
                                    fill="clear"
                                    (click)="showPass = !showPass"
                                >
                                    <bazis-icon
                                        [name]="showPass ? 'eye-crossed' : 'eye'"
                                    ></bazis-icon>
                                </bazis-button>
                            </div>
                        </div>
                    </div>

                    <ng-container
                        *ngIf="{
                            login: notValidAuth$ | async
                        } as error"
                    >
                        <bazis-display-error
                            *ngIf="error.login"
                            [validationErrorMessages]="validationErrorMessages"
                            [errorKey]="errorKey"
                            [hasIcon]="true"
                            class="bh-margin-top-1x"
                        ></bazis-display-error>
                    </ng-container>

                    <bazis-button
                        class="bh-margin-top-6x"
                        color="action"
                        size="large"
                        type="submit"
                        [disabled]="loginForm.status !== 'VALID' || data.authing"
                    >
                        {{ t('login.submit') }}
                    </bazis-button>
                </form>
                <ng-container *ngIf="data.types.esia || data.types.cert">
                    <fieldset>
                        <legend>{{ t('login.otherEnter') }}</legend>
                    </fieldset>
                </ng-container>
            </div>

            <ng-container *ngIf="data.types.esia && authTypesToTabsMap.esia === userType.value">
                <bazis-button
                    [fill]="data.types.password ? 'outline' : 'solid'"
                    color="action"
                    size="large"
                    (click)="esiaLogin()"
                >
                    {{ t('login.toESIA') }}
                    <bazis-icon
                        slot="start"
                        src="/assets/icons/other/gosusligi-logo-light.svg"
                        size="s"
                    ></bazis-icon>
                </bazis-button>
                <ng-container *ngIf="data.types.cert && !data.types.password">
                    <fieldset>
                        <legend>{{ t('login.otherEnter') }}</legend>
                    </fieldset>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="data.types.cert && authTypesToTabsMap.cert === userType.value">
                <bazis-button
                    fill="outline"
                    color="action"
                    size="large"
                    (click)="authBySign()"
                >
                    <bazis-icon
                        slot="start"
                        src="/assets/icons/other/key.svg"
                        size="s"
                    ></bazis-icon>
                    {{ t('login.signEntrance') }}
                </bazis-button>
            </ng-container>

            <ng-container *ngIf="userType.value === 'userOrganization'">
                <bazis-button
                    fill="outline"
                    color="action"
                    size="large"
                    class="bh-margin-top-4x"
                    (click)="signUp()"
                >
                    {{ t('login.signUp.action') }}
                </bazis-button>
            </ng-container>

            <!-- by phone -->
            <ng-container *ngIf="data.types.phone && authTypesToTabsMap.phone === userType.value">
                <form
                    (keyup.enter)="phoneForm.valid && sendCode()"
                    (submit)="phoneForm.valid && sendCode()"
                    [formGroup]="phoneForm"
                >
                    <bazis-input-default
                        formControlName="phone"
                        [maskSettings]="phoneMaskSettings"
                        [beforeWrite]="phoneInputTransform"
                        titleKey="login.phoneNumber"
                        noteKey="login.phoneNumberDescription"
                        type="tel"
                        leftIcon="/assets/icons/other/phone.svg"
                    ></bazis-input-default>
                    <bazis-button
                        class="bh-margin-top-6x"
                        color="action"
                        size="large"
                        type="submit"
                        [disabled]="
                            !phoneForm.controls.phone.value ||
                            phoneForm.status !== 'VALID' ||
                            data.authing
                        "
                        >{{ t('login.receiveSms') }}
                    </bazis-button>
                </form>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="showPhoneCodeComponent && data.types">
            <form
                [formGroup]="codeForm"
                (keyup.enter)="codeForm.valid && authByPhone()"
                (submit)="codeForm.valid && authByPhone()"
            >
                <p
                    class="bazis-p_major bh-text-center"
                    [innerHTML]="t('login.smsSent', { number: phoneForm.value.phone | phone })"
                ></p>

                <app-input-symbol
                    [symbolAmount]="4"
                    type="number"
                    formControlName="code"
                    (allSymbolsEntered)="authByPhone()"
                ></app-input-symbol>
                <div
                    *ngIf="{
                        sendPhoneCodeError: sendPhoneCodeError$ | async
                    } as error"
                    class="bh-text-center error"
                >
                    <!-- TODO: доработать компонент bazis-display-error (в т.ч. и в базисе),
                    чтобы в него можно было передать не код errorKey, но и error (если нам бек ее текстом присылает) -->
                    <span
                        class="bazis-small"
                        color="danger"
                        >{{ error.sendPhoneCodeError }}</span
                    >
                </div>

                <bazis-button
                    *ngIf="nextPhoneTry - data.timer > 0; else sendCodeBtn"
                    class="bh-margin-bottom-4x"
                    [disabled]="true"
                    fill="outline"
                    color="action"
                    size="large"
                >
                    {{
                        t('login.repeatCodeAfter', {
                            number: nextPhoneTry - data.timer
                        })
                    }}
                </bazis-button>
                <ng-template #sendCodeBtn>
                    <bazis-button
                        class="bh-margin-bottom-4x"
                        fill="outline"
                        color="action"
                        size="large"
                        [disabled]="data.authing"
                        (click)="sendCode()"
                    >
                        {{ t('login.repeatCode') }}
                    </bazis-button>
                </ng-template>

                <bazis-button
                    class="bh-margin-bottom-4x"
                    color="action"
                    size="large"
                    [disabled]="codeForm.status !== 'VALID' || data.authing"
                    (click)="authByPhone()"
                >
                    {{ t('login.enter') }}
                </bazis-button>

                <bazis-button
                    fill="clear"
                    color="action"
                    size="large"
                    (click)="changeNumber()"
                >
                    {{ t('login.changeNumber') }}
                </bazis-button>
            </form>
        </ng-container>
        <hr />
        <div
            class="bh-text-left bazis-text_secondary"
            [innerHTML]="t('login.terms', {privacyPolicy, userAgreement})"
        ></div>
    </div>
</ng-container>
<!-- DO NOT REMOVE -->
<ng-container *ngIf="userTypeChanges$ | async"></ng-container>
