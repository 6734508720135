import * as L from 'leaflet';
import '@bazis/map/plugins/canvas-marker';

const CanvasMarkerText = L.CanvasMarker.extend({
    _updatePath() {
        L.CanvasMarker.prototype._updatePath.call(this);
        this._renderer._updateCanvasText(this);
    },
});

export function canvasMarkerText(...options) {
    return new CanvasMarkerText(...options);
}

L.canvasMarkerText = canvasMarkerText;

export default CanvasMarkerText;
