import { Injectable, Injector } from '@angular/core';
import * as L from 'leaflet';
import '@bazis/map/plugins/path-draggable';
import {
    MapDraggedEvent,
    MapLayers,
    MapLayerSettings,
    MapSettings,
    MapTileSettings,
} from '@bazis/map/models/map.models';
import { CanvasMarkerClass } from '@bazis/map/classes/canvas-marker.class';
import { createCustomElement, NgElement, WithProperties } from '@angular/elements';
import { BazisDefaultPopupComponent } from '@bazis/map/components/popups/default-popup.component';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { RouteLineClass } from '@bazis/map/classes/route-line.class';
import ExtendedCanvas from '@bazis/map/plugins/extended-canvas';
import { fromGeoJson } from '@bazis/map/services/map-utils';
import { PolygonClass } from '@bazis/map/classes/polygon.class';
@Injectable()
export class BazisMapService {
    protected _mapLayers: {
        [index: string]: MapLayerSettings & {
            leafletLayer: any;
        };
    } = {};

    protected _isDragging = false;

    map: L.Map;

    renderer: L.Renderer;

    popup: L.Popup;

    tooltip: L.Tooltip;

    popupActions = new TemplateObservable(null);

    selected = new TemplateObservable(null);

    dragged: TemplateObservable<MapDraggedEvent> = new TemplateObservable(null);

    mapClicked = new TemplateObservable(null);

    mapOptions: MapSettings;

    constructor(protected injector: Injector) {}

    initMap(domElement, options: MapSettings) {
        if (this.map) return;

        this.map = L.map(domElement, {
            center: options.defaultLocation,
            zoomControl: false,
            attributionControl: false,
            zoom: options.defaultZoom || 7,
            zoomMin: 0,
            zoomMax: 18,
            worldCopyJump: true,
            preferCanvas: true,
            ...options.additionalSettings,
        });

        this.mapOptions = options;

        let tileLayerOptions = this.getInitialTileSettings(options);
        this.setTileLayer(tileLayerOptions);

        this.map.createPane('canvasPane');
        this.map.getPane('canvasPane').style.zIndex = 400;

        this.renderer = new ExtendedCanvas({ pane: 'canvasPane', tolerance: 0 });

        this.map.on('popupclose', (e) => {
            if (
                this.selected._ &&
                e?.popup?.options?.layerId === this.selected._.layerId &&
                this.selected._.object?.options?.properties?.id ===
                    e.popup.options.object.options.properties.id
            ) {
                this.setSelection(this.selected._.layerId, this.selected._.object, false);
            }
            this.popup = null;
        });
        this.map.on('popupopen', () => {
            //this.popup = null;
        });
        let prevZoom = this.map._zoom;
        this.map.on('zoomend', (e) => {
            const currentZoom = this.map._zoom;
            if (Math.floor(prevZoom) !== Math.floor(currentZoom)) {
                this.redrawZoomLayers();
            }
            prevZoom = currentZoom;
        });

        this.map.on('click', (e) => {
            if (this.selected._) {
                this.setSelection(this.selected._.layerId, this.selected._.object, false);
            }
            if (this._isDragging) return;
            Object.keys(this._mapLayers).forEach((layer) => {
                if (this._mapLayers[layer].isEditable) {
                    const ids = Object.keys(this._mapLayers[layer].leafletLayer._layers);
                    if (ids.length === 0) return;
                    const marker = this._mapLayers[layer].leafletLayer._layers[ids[0]];
                    marker.setLatLng(e.latlng);
                    marker.fire('drag');
                }
            });
        });
    }

    getInitialTileSettings(options = this.mapOptions) {
        if (!options.tiles?.length) return { tile: options.tile, id: 'tileLayer' };

        const savedTileId = options.id ? localStorage.getItem('selectedTiles-' + options.id) : null;
        const savedTile = savedTileId ? options.tiles.find((v) => v.id === savedTileId) : null;
        const defaultTile = options.tiles.find((v) => v.isDefault) || options[0];
        return savedTile || defaultTile;
    }

    setTileLayer(tileLayerSettings: MapTileSettings) {
        const tileLayer = L.tileLayer(tileLayerSettings.tile.url, {
            tileSize: tileLayerSettings.tile.size,
        });

        tileLayer.addTo(this.map).bringToBack();

        if (this._mapLayers.tileLayer) this._mapLayers.tileLayer.leafletLayer.remove();

        this._mapLayers.tileLayer = {
            id: tileLayerSettings.id,
            updatedTime: new Date().getTime(),
            leafletLayer: tileLayer,
            zIndex: 1,
        };

        if (this.mapOptions.id && this.mapOptions.tiles?.length) {
            localStorage.setItem('selectedTiles-' + this.mapOptions.id, tileLayerSettings.id);
        }
    }

    setLayers(layers: MapLayers) {
        Object.keys(layers).forEach((layerId) => {
            if (
                !this._mapLayers[layerId] ||
                this._mapLayers[layerId].updatedTime < layers[layerId].updatedTime
            ) {
                this.setLayer(layers[layerId]);
            }
        });
    }

    setLayer(layer: MapLayerSettings, avoidRecalculating = false) {
        let tooltipToReopen = null;
        if (this._mapLayers[layer.id]) {
            if (layer.isEditable !== undefined) {
                Object.keys(this._mapLayers[layer.id].leafletLayer._layers).forEach((layerId) => {
                    this._mapLayers[layer.id].leafletLayer._layers[layerId].dragging.disable();
                });
            }
            if (this.tooltip?.options?.layerId === layer.id) {
                tooltipToReopen = { ...this.tooltip.options };
            }
            this._mapLayers[layer.id].leafletLayer.clearLayers();
        }
        const existed = this._mapLayers[layer.id]?.leafletLayer;
        const hadTooltip = this._mapLayers[layer.id]?.hasTooltip;
        const featureGroup = this._mapLayers[layer.id]?.leafletLayer || new L.FeatureGroup([]);
        if (!avoidRecalculating) {
            layer.objects = layer.objects.map((v) => {
                return {
                    ...v,
                    coordinates: fromGeoJson(v.geometry),
                };
            });

            if (layer.zoomObjects) {
                Object.keys(layer.zoomObjects).forEach((zoom) => {
                    layer.zoomObjects[zoom] = layer.zoomObjects[zoom].map((v) => {
                        return {
                            ...v,
                            coordinates: fromGeoJson(v.geometry),
                        };
                    });
                });
            }
        }

        switch (layer.type) {
            case 'marker':
                layer = CanvasMarkerClass.processLayerSettings(
                    layer,
                    this.map._layersMinZoom,
                    this.map._layersMaxZoom,
                );
                CanvasMarkerClass.drawMarkers(layer, featureGroup, this.renderer, this.map._zoom);
                break;
            case 'line':
                layer = RouteLineClass.processLayerSettings(layer);
                RouteLineClass.drawRouteLine(layer, featureGroup, this.renderer, this.map._zoom);
                break;
            case 'polygon':
                layer = PolygonClass.processLayerSettings(layer);
                PolygonClass.drawPolygon(layer, featureGroup, this.renderer);
                break;
        }

        const needUpdateLayersOrder = true;

        this._mapLayers[layer.id] = {
            ...layer,
            leafletLayer: featureGroup,
        };
        if (!existed) {
            featureGroup.addTo(this.map);
        }

        if (
            (!existed || layer.forceFit) &&
            layer.fitBounds &&
            this._mapLayers[layer.id].objects.length > 0
        ) {
            this.fitBounds(layer.id);
        }

        if (this._hovered?.layerId === layer.id) {
            this._hovered = null;
        }

        if (
            !existed ||
            (!hadTooltip && this._mapLayers[layer.id].hasTooltip) ||
            (this._mapLayers[layer.id].hasPopup &&
                this._mapLayers[layer.id].openPopupOnHover &&
                !existed)
        ) {
            this.initHoverListeners(layer.id);
        }
        if (tooltipToReopen) {
            const newLayerObjectKey = Object.keys(featureGroup._layers).find(
                (key) =>
                    featureGroup._layers[key].options.properties.id ===
                    tooltipToReopen.object.options.properties.id,
            );
            if (newLayerObjectKey) {
                this.openTooltip(tooltipToReopen.layerId, featureGroup._layers[newLayerObjectKey]);
            }
            tooltipToReopen = null;
        }
        if (hadTooltip && !this._mapLayers[layer.id].hasTooltip) {
            this.offHoverListeners(layer.id);
            this.closeTooltip();
        }
        if (!existed) this.initClickListeners(layer.id);

        if (!existed) this.initEditListeners(layer.id);

        if (needUpdateLayersOrder) this.updateLayersOrder();
    }

    redrawZoomLayers() {
        Object.keys(this._mapLayers).forEach((layerId) => {
            const layer = this._mapLayers[layerId];
            if (layer.zoomIconSettings || layer.zoomObjects || layer.zoomIcon) {
                this.setLayer(this._mapLayers[layerId], true);
                // close popup if we have an open popup with object id which doesn't exist in redrawn layer
                if (
                    this.popup &&
                    this.popup.options.layerId === layerId &&
                    (this._mapLayers[layerId].hidePopupAfterZoom ||
                        !this.popup.options?.object?.options?.properties?.id ||
                        !Object.values(this._mapLayers[layerId].leafletLayer._layers).find(
                            (v: any) =>
                                v.options.properties.id ===
                                this.popup.options.object.options.properties.id,
                        ))
                ) {
                    this.map.closePopup();
                }
                if (
                    !this._mapLayers[layerId].avoidReselectionOnZoom &&
                    this.selected._ &&
                    this.selected._.layerId === layerId &&
                    this.selected._.object?.options?.properties?.id
                ) {
                    const object = Object.values(
                        this._mapLayers[layerId].leafletLayer._layers,
                    ).find(
                        (v: any) =>
                            v.options.properties.id ===
                            this.selected._.object.options.properties.id,
                    );
                    if (object) this.setSelection(this.selected._.layerId, object, true);
                }
            }
        });
    }

    updateLayersOrder() {
        Object.keys(this._mapLayers)
            .sort((a, b) => this._mapLayers[a].zIndex - this._mapLayers[b].zIndex)
            .forEach((layerId) => {
                this._mapLayers[layerId].leafletLayer.bringToFront();
            });
    }

    closeTooltip() {
        if (!this.tooltip) return;
        this.map.closeTooltip(this.tooltip);
        this.tooltip = null;
    }

    private _hovered = null;

    private _hoveredTimeout;

    initHoverListeners(layerId: string) {
        const featureGroup = this._mapLayers[layerId].leafletLayer;
        const layerProperties = this._mapLayers[layerId];

        featureGroup.on('mouseover', (e) => {
            if (layerProperties.hasTooltip && layerProperties.tooltipSettings) {
                if (this.tooltip && layerProperties.tooltipSettings.isRevertActionTooltip) {
                    this.closeTooltip();
                } else {
                    this.openTooltip(layerId, e.layer);
                }
            }

            if (
                layerProperties.hasPopup &&
                layerProperties.openPopupOnHover &&
                (layerProperties.openPopupOnHoverForCluster ||
                    !e.layer.options.properties.count ||
                    e.layer.options.properties.count <= 1) &&
                (!this.popup ||
                    this.popup.options.layerId !== layerId ||
                    this.popup.options.object.options.properties.id !==
                        e.layer.options.properties.id)
            ) {
                this._hovered = { layerId, id: e.layer.options.properties.id };

                this._hoveredTimeout = setTimeout(() => {
                    if (
                        this._hovered &&
                        this._hovered.layerId === layerId &&
                        this._hovered.id === e.layer.options.properties.id
                    )
                        this.openPopup(layerId, e.layer);
                }, 1000);
            }
        });

        featureGroup.on('mouseout', (e) => {
            if (layerProperties.hasTooltip && layerProperties.tooltipSettings) {
                if (layerProperties.tooltipSettings.isRevertActionTooltip) {
                    this.openTooltip(layerId, e.layer);
                } else {
                    this.closeTooltip();
                }
            }

            if (
                layerProperties.hasPopup &&
                layerProperties.openPopupOnHover &&
                (layerProperties.openPopupOnHoverForCluster ||
                    !e.layer.options.properties.count ||
                    e.layer.options.properties.count <= 1) &&
                this.popup &&
                this.popup.options.layerId === layerId &&
                this.popup.options.object.options.properties.id === e.layer.options.properties.id
            ) {
                this.map.closePopup();
            }

            if (
                this._hovered &&
                this._hovered.id === e.layer.options.properties.id &&
                this._hovered.layerId === layerId
            ) {
                this._hovered = null;
                clearTimeout(this._hoveredTimeout);
            }
        });
    }

    offHoverListeners(layerId: string) {
        const featureGroup = this._mapLayers[layerId].leafletLayer;
        featureGroup.off('mouseover');
        featureGroup.off('mouseout');
    }

    initClickListeners(layerId: string) {
        const featureGroup = this._mapLayers[layerId].leafletLayer;
        const layerProperties = this._mapLayers[layerId];

        featureGroup.on('click', (e) => {
            if (this._mapLayers[layerId].switchOffToggleClickMode) {
                this.setSelection(layerId, e.layer, true);
            } else {
                this.toggleSelection(layerId, e.layer);
            }
            if (
                e.layer.options.properties.count > 1 &&
                !this._mapLayers[layerId].skipZoomInOnClick
            ) {
                this.map.flyTo(e.layer._latlng, e.layer.options.properties.nextZoom, {
                    animate: Math.abs(e.layer.options.properties.nextZoom - this.map._zoom) < 3,
                });
                return;
            }
            if (layerProperties.hasPopup && !layerProperties.openPopupOnHover)
                this.openPopup(layerId, e.layer);
        });
    }

    initEditListeners(layerId: string) {
        const featureGroup = this._mapLayers[layerId].leafletLayer;
        featureGroup.on('drag', (e) => {
            this.dragged.set({
                layerId,
                objectId: e.objectId,
                coordinates: e.coordinates,
            });
        });
        featureGroup.on('dragstart', (e) => {
            this._isDragging = true;
        });
        featureGroup.on('dragend', (e) => {
            setTimeout(() => (this._isDragging = false));
        });
    }

    // selection logic (simple and toogle)
    setSelection(layerId, objectLayer, selectValue: boolean) {
        const layerProperties = this._mapLayers[layerId];
        const objectProperties = objectLayer.options.properties;

        let newProperties = objectProperties.defaultOptions || layerProperties.defaultOptions;

        if (this.selected._ && selectValue) {
            this.setSelection(this.selected._.layerId, this.selected._.object, false);
        }

        if (selectValue) {
            newProperties =
                objectProperties.selectedOptions ||
                layerProperties.selectedOptions ||
                newProperties;
        }

        this.selected.set(
            selectValue
                ? {
                      layerId,
                      objectId: objectProperties.id,
                      object: objectLayer,
                  }
                : null,
        );

        L.setOptions(objectLayer, newProperties);
        objectLayer.redraw();
        this.bringToFront(objectLayer, layerId);
    }

    bringToFront(objectLayer, layerId) {
        objectLayer.bringToFront();
        // все другие слои, у кот. z-index выше текущего, надо поднять наверх
        const targetLayer = this._mapLayers[layerId];
        Object.keys(this._mapLayers).forEach((layer) => {
            if (this._mapLayers[layer].zIndex > targetLayer.zIndex) {
                this._mapLayers[layer].leafletLayer.bringToFront();
            }
        });
    }

    toggleSelection(layerId, objectLayer) {
        const properties = objectLayer.options.properties;
        const currentValue =
            this.selected._?.layerId === layerId && this.selected._?.objectId === properties.id;
        this.setSelection(layerId, objectLayer, !currentValue);
    }

    selectById(layerId, objectId, openPopup = false) {
        if (
            this.selected._ &&
            this.selected._.layerId === layerId &&
            this.selected._.objectId === objectId
        )
            return;
        const objects = this._mapLayers[layerId].leafletLayer._layers;
        const leafletId = Object.keys(objects).find(
            (id) => objects[id].options.properties.id === objectId,
        );
        this.setSelection(layerId, objects[leafletId], true);
        if (objects[leafletId]._latlng) {
            this.centerOnCoordinates(
                objects[leafletId]._latlng.lat,
                objects[leafletId]._latlng.lng,
            );
        } else if (objects[leafletId]._bounds) {
            this.map.fitBounds(objects[leafletId]._bounds);
        }
        if (openPopup) {
            this.openPopup(layerId, objects[leafletId]);
        }
    }

    centerOnCoordinates(lat, lng, minZoom = null, animationDuration = 0.35) {
        const currentZoom = this.map.getZoom();
        const zoom = minZoom && currentZoom < minZoom ? minZoom : currentZoom;
        this.map.setView(new L.LatLng(lat, lng), zoom, {
            animate: animationDuration > 0,
            duration: animationDuration,
        });
    }

    fitBounds(layerId) {
        const options = this.map.options.padding ? { ...this.map.options.padding } : {};
        this.map.fitBounds(this._mapLayers[layerId].leafletLayer.getBounds(), options);
    }

    fitLayersBounds(layerIds, maxZoom = 7) {
        const bounds = layerIds.reduce((acc, layerId) => {
            if (!this._mapLayers[layerId]?.leafletLayer) return acc;
            const layerBounds = this._mapLayers[layerId]?.leafletLayer.getBounds();
            if (!layerBounds.isValid()) return acc;
            return acc ? acc.extend(layerBounds) : layerBounds;
        }, null);
        if (!bounds) return;
        const paddingOptions = this.map.options.padding ? { ...this.map.options.padding } : {};
        this.map.fitBounds(bounds, { ...paddingOptions, maxZoom });
    }

    // work with tooltip

    openTooltip(layerId, object) {
        this.closeTooltip();
        const properties = object.options.properties;
        const settings = this._mapLayers[layerId].tooltipSettings;
        let tooltipContent = settings.tooltipText || settings.tooltipContent;
        if (settings.tooltipFunction) {
            tooltipContent = `<div class="leaflet-tooltip__content">${settings.tooltipFunction(
                properties.tooltipParams,
            )}</div>`;
        }
        let offset = settings.offset
            ? L.point(settings.offset[0], settings.offset[1])
            : object?._offset
            ? L.point(object._offset.x / 2, -object._height + object._offset.y)
            : L.point(0, 0);

        this.tooltip = L.tooltip({
            offset,
            direction: settings.direction || 'center',
            className: settings.className,
        })
            .setContent(`${tooltipContent}`)
            .setLatLng(object.getLatLng());

        this.tooltip.options.object = object;
        this.tooltip.options.layerId = layerId;

        // to prevent jumps when immediately opens right after close
        this.map.openTooltip(this.tooltip);
    }

    // work with popups

    openPopup(layerId, object) {
        const properties = object.options.properties;
        const popupContent = this.generatePopupComponent(
            this._mapLayers[layerId].popupComponent || BazisDefaultPopupComponent,
            {
                ...this._mapLayers[layerId].popupComponentParams,
                ...properties.popupParams,
            },
            this._mapLayers[layerId].popupComponentTag || 'map-popup-element',
        );

        const offset = object?._offset
            ? L.point(object._offset.x / 2, -object._halfHeight + object._offset.y)
            : L.point(0, 0);
        const coords = object.getLatLng ? object.getLatLng() : object.getBounds().getCenter();
        console.log(object.getLatLng);
        this.popup = L.popup({ offset, autoPan: false }).setLatLng(coords).setContent(popupContent);
        this.popup.options.object = object;
        this.popup.options.layerId = layerId;
        this.popup.openOn(this.map);
    }

    generatePopupComponent(component, componentParams, componentTag) {
        // This uses the new custom-element method to add the popup to the DOM.
        // Create element
        try {
            // Convert `popupComponent` to a custom element.
            const PopupElement = createCustomElement(component, { injector: this.injector });
            // Register the custom element with the browser.
            customElements.define(componentTag, PopupElement);
        } catch {}
        // Create element
        const popupEl: NgElement & WithProperties<typeof component> = document.createElement(
            componentTag,
        ) as any;

        // Listen to the clicked event
        popupEl.addEventListener('action', (event: CustomEvent) => {
            this.popupActions.set({
                layerId: componentParams.layerId,
                objectId: componentParams.id,
                event: event.detail,
            });
        });
        // Set popup settings
        popupEl.settings = componentParams;

        return popupEl;
    }
}
